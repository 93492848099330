export const gateway = {
  apiUrl: '',
  appName: 'IoTSense',
  appVersion: '4.0.0',
  appType: 'saas',
  // recaptchaKey: '6Lc8P64bAAAAAIhNoTPhpkc0tq3PiQDNg2iuKd9N',
  rzpKey: 'rzp_test_prjJk3Mphqtfeo',
  isUpgradeImmediately: false,
  isCancelImmediately: false,
  showOnlyFreeTrial: true,
  getYear: () => {
    return new Date().getFullYear();
  },
};

// http://localhost:3000
export const hierarchy = {
  //Add plural forms of the name, eg. 'devices' and not 'device'
  level0: 'Things',
  level1: 'Machines',
  level2: 'Machine Groups',
  level3: 'Plants',
};
export const ENV = {
  DEV_KEY: 'rzp_test_prjJk3Mphqtfeo',
  PROD_KEY: 'rzp_test_prjJk3Mphqtfeo',
};
