import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TriggerDialogComponent } from './routes/resourcemanagement/trigger-dialog/trigger-dialog.component';
import { AppComponent } from './app.component';
import { ConnectivityService } from '../app/routes/settings/services/connectivity.service';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { CloneThingRecordComponent } from './routes/resourcemanagement/clone-thing-record/clone-thing-record.component';
import { SwitchOwnerThingComponent } from './routes/resourcemanagement/switch-owner-thing/switch-owner-thing.component';
import { NgxTagsInputModule } from 'ngx-tags-input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DatePipe } from '@angular/common';
import { PagesModule } from './routes/pages/pages.module';
import { EmailVerificationSuccessComponent } from './routes/pages/email-verification-success/email-verification-success.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InterceptorService } from './layout/loader/interceptor.service';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    TriggerDialogComponent,
    CloneThingRecordComponent,
    SwitchOwnerThingComponent,
    EmailVerificationSuccessComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    NgxTagsInputModule,
    MatDialogModule,
    MatFormFieldModule,
    PagesModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    DatePipe,
    ConnectivityService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
