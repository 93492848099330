export const PLANS: Array<any> = [
  {
    type: 'basic',
    duration: 'monthly',
    id: 'plan_IAlHcjUrNdDQP6'
  },
  {
    type: 'basic',
    duration: 'yearly',
    id: 'plan_IAlIQRaeRY5mN7',
  },
  {
    type: 'pro',
    duration: 'monthly',
    id: 'plan_IAlOKwFJ9DG5aG',
  },
  {
    type: 'pro',
    duration: 'yearly',
    id: 'plan_IAlNpcijQ9sHc2',
  },
];
