<section class="vh-100">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100" style="flex-direction: column;">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div style="color: #28519d;text-align: center;">
          <mat-icon style="font-size: 60px;">check_circle</mat-icon>
        </div>
        <div style="text-align: center;">
          <h1 style="margin-top: 10px;">Password Changed!</h1>
          <h3>Your password has been changed successfully.</h3>
          <span style="display: flex;justify-content: center;">
            <mat-icon style="font-size: 21px;color: #28519d;">shortcut</mat-icon><a href="/login"
              style="text-decoration: underline;font-size: medium;">Go to login page
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>