<div class="wrapper">
    <div class="abs-center wd-xl">
        <!-- START card-->
        <img class="img-fluid mb-4" src="../../../../assets/img/logo2.svg" alt="IoTSense logo" height="500"
            width="400" />
        <div class=" card">
            <div class="card-body">
                <div class="text-center mb-4 ">
                    <div class="text-lg mb-3">404</div>
                    <p class="lead m0">Oops! We couldn't find this page.</p>
                    <p>The page you are looking for does not exists.</p>
                </div>
                <p class="text-center">Here are some helpful links instead:</p>
                <ul class="list-inline text-center text-sm mb-4">
                    <li class="list-inline-item">
                        <a class="text-muted" [routerLink]="'/login'">Login</a>
                    </li>
                    <li class="text-muted list-inline-item">|</li>
                    <li class="list-inline-item">
                        <a class="text-muted" [routerLink]="'/signup'">Signup</a>
                    </li>
                </ul>
                <div class="p-3 text-center">
                    <span>&copy;</span>
                    <span>{{ settings.getAppSetting('year') }}</span>
                    <span class="mx-2">-</span>
                    <span>{{ settings.getAppSetting('name') }}</span>
                    <br />
                </div>
            </div>
        </div>

    </div>
</div>