<tabset [justified]="true">
  <div class="col-md-12">
    <h2 class="text-center text-thin mt-4">
      Notifications
      <a
        [hidden]="notifications?.length === 0"
        style="font-size: small; color: #6e2525"
        class="ml-2 pull-right pointer"
        (click)="clearRecentNotifications()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </h2>
    <hr />
    <h4 class="text-center text-thin" [hidden]="notifications?.length != 0">
      <span>No new notifications</span>
    </h4>
    <div
      class="mb-2"
      *ngFor="let notification of notifications"
      ng-class="notification.style"
    >
      <mat-expansion-panel
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header
          [ngClass]="{
            'blue-panel': notification.level === 'info',
            'warn-panel': notification.level == 'warn',
            'danger-panel': notification.level == 'error',
            'green-panel': notification.level == 'other'
          }"
        >
          <mat-panel-title *ngIf="notification.level === 'info'" style="text-transform:capitalize;">
           <img src="../../../assets/images/info.png" style="height: auto;
           width: 18%;">&nbsp;&nbsp; {{ notification.level }}
          </mat-panel-title>
          <mat-panel-title *ngIf="notification.level === 'warn'" style="text-transform:capitalize;">
            <img src="../../../assets/images/warning.png" style="height: auto;
            width: 18%;">&nbsp;&nbsp; {{ notification.level }}
           </mat-panel-title>
           <mat-panel-title *ngIf="notification.level === 'error'" style="text-transform:capitalize;">
            <img src="../../../assets/images/cancel.png" style="height: auto;
            width: 18%;">&nbsp;&nbsp; {{ notification.level }}
           </mat-panel-title>
           <mat-panel-title *ngIf="notification.level === 'other'" style="text-transform:capitalize;">
            <img src="../../../assets/images/ellipsis.png" style="height: auto;
            width: 18%;">&nbsp;&nbsp; {{ notification.level }}
           </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mt-2" style="text-align: center">
          <p
            class="ellipsis"
            style="text-overflow: ellipsis; margin-bottom: 0px"
          >
            {{ notification.message }}
          </p>
          <br />
          <hr style="margin-top: 0; margin-bottom: 0" />
          <span style="font-size: 84%">{{
            notification.timestamp | date: "short"
          }}</span>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</tabset>
