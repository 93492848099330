import { Component, OnInit, Injector, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from '../../../shared/globalvariables/globalVariable.config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { document } from 'ngx-bootstrap/utils';

declare let pendo: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService, GlobalService],
})
export class LoginComponent implements OnInit {
  valForm: FormGroup;
  cookieValue = 'UNKNOWN';
  workInProgress = false;
  toaster: any;
  toasterConfig: any;
  hide = true;
  profile;
  user: any;

  constructor(
    public globals: GlobalService,
    private _snackBar: MatSnackBar,
    public settings: SettingsService,
    fb: FormBuilder,
    private _loginService: LoginService,
    public injector: Injector,
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {
    this.valForm = fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }
  login(value) {
    this.toaster = {
      type: 'success',
      title: 'Title',
      text: 'Message',
    };
    //becrypt username and password.
    let obj = {
      username: '',
      password: '',
    };

    // obj.username = btoa(value.username);
    // obj.password = btoa(value.password);

    obj.username = this._loginService.encryptData(value.username);
    obj.password = this._loginService.encryptData(value.password);

    // console.log("\n  \n  ~🚀 login \n  ~🚀 obj", obj);

    this._loginService.doLogin(obj).subscribe(
      (data: any) => {
        // Decoding roles before setting data in window['user']
        data.roles[0] = atob(data.roles[0]);
        window['user'] = data;

        ///////////////// PENDO FOR DEMO AND UAT INSTANCE ////////////////////

        this.ngZone.runOutsideAngular(function () {
          window.pendo?.identify({
            visitor: {
              id: data._id + '_' + document.domain,
            },
            account: {
              id: data.email,
            },
          });
        });

        this.profile = data;
        var cookies = this.profile;

        this.cookieService.set('userid', this.profile._id, {
          secure: true,
          sameSite: 'Strict',
        });
        this.cookieService.set('displayName', this.profile.displayName, {
          secure: true,
          sameSite: 'Strict',
        });
        if (cookies['roles'].indexOf('operator') > -1) {
          this.router.navigate(['taskmanagement/task-creation']);
        } else {
          if (this.profile.is_selected) {
            this.router.navigate(['role-selection']);
          } else {
            if (this.profile.subId?.status == 'cancelled') {
              this.router.navigate(['subscription']);
            } else {
              this.router.navigate(['home']);
            }
          }
        }
      },
      (error) => {
        this._snackBar.open(error.error.message + '.', 'Dismiss', {
          duration: 3000,
        });
      }
    );
  }
  getData() {
    this._loginService.getData().subscribe((data) => {
      // console.log(data);
    });
  }
  onBlur1(e) {
    if (e.target.value == '') {
      this._snackBar.open('Password is required.', 'Dismiss', {
        duration: 3000,
      });
    }
  }
  onBlur(e) {
    if (e.target.value == '') {
      this._snackBar.open('Username is required.', 'Dismiss', {
        duration: 3000,
      });
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.login(value);
    }
  }
  ngOnInit() {
    this.router = this.injector.get(Router);

    if (this.globals.user) {
      this.router.navigate(['home']);
    }
  }


}
