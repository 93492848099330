import { Component, EventEmitter, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  showBanner: boolean;
  bannerInfo: any = {};
  bannerClass: string;
  iconType;

  constructor(public ss: SharedService) {}

  ngOnInit() {
    this.ss.showBanner.subscribe((flag) => {
      this.bannerInfo.flag = flag;
    });
    this.ss.updateBannerInfo.subscribe((bannerInfo1: any) => {
      this.iconType = bannerInfo1.type;
      this.bannerInfo = bannerInfo1;
      switch (bannerInfo1.type) {
        case 'info':
          this.bannerClass = 'alert blue-panel';
          break;
        case 'warn':
          this.bannerClass = 'alert warn-panel';
          break;
        case 'error':
          this.bannerClass = 'alert danger-panel';
          break;
        default:
          this.bannerClass = 'alert green-panel';
      }
    });
  }
  closeBanner() {
    this.bannerInfo = {};
    this.ss.hideNotificationBanner();
  }
}
