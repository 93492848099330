import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThingService } from '../services/thing.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clone-thing-record',
  templateUrl: './clone-thing-record.component.html',
  styleUrls: ['./clone-thing-record.component.scss'],
})
export class CloneThingRecordComponent implements OnInit {
  deviceName;
  cloneDevice: any;
  toaster: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ts: ThingService,
    private _router: Router,
    private _route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.toaster = {
      type: 'success',
      title: 'IoTSense',
      text: 'Message',
    };
  }
  create(data, name) {
    delete data._id;
    data.name = name;
    data.attributes = data.attributes;
    data.IPAddress = data.snmpIP;

    this.ts.createThing(data).subscribe(
      (data: any) => {
        this.toaster.type = 'success';
        this.toaster.text = 'Record is cloned successfully .';
        this.toastr[this.toaster.type](this.toaster.text, this.toaster.title);
      },
      (err) => {
        this.toaster.type = 'error';
        this.toaster.text = err.error.message;
        this.toastr[this.toaster.type](this.toaster.text, this.toaster.title);
      }
    );

    this._router.navigateByUrl('.', { skipLocationChange: true }).then(() => {
      this._router.navigate(['resourcemanagement/things']);
    });
  }
}
