import { Injectable } from '@angular/core';
declare var $: any;

@Injectable()
export class GlobalService {

    public user: any;

    checkauth(user) {
    console.log("GlobalService -> checkauth -> user", user)

        return user;
    }

}
