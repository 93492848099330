import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailLinkResendService } from './email-link-resend.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-link-resend',
  templateUrl: './email-link-resend.component.html',
  styleUrls: ['./email-link-resend.component.scss'],
})
export class EmailLinkResendComponent implements OnInit {
  registrationForm: FormGroup;
  toaster: { type: string; title: string; text: string };

  constructor(
    private _toaster: ToastrService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private emailService: EmailLinkResendService
  ) {}

  ngOnInit(): void {
    this.registrationForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onBlur(e) {
    if (e.target.value == '') {
      this._snackBar.open('Email is required.', 'Dismiss', {
        duration: 3000,
      });
    }
  }

  submitForm() {
    const { email } = this.registrationForm.value;
    let data = {
      email: email,
    };

    this.emailService.resendEmail(data).subscribe({
      next: (result) => {
        this.toaster = {
          type: 'success',
          title: 'Title',
          text: 'Message',
        };
        this.toaster.title = 'IoTSense';
        this.toaster.text =
          'Email has sent successfully, Please check your email.';
        this._toaster['info'](this.toaster.text, this.toaster.title);
        this.router.navigate(['login']);
      },
      error: (responce: HttpErrorResponse) => {
        let errorMsg = responce.error.error;
        const nameCapitalized =
          errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
        this._snackBar.open(nameCapitalized + '.', 'Dismiss', {
          duration: 3000,
        });
      },
    });
  }
}
