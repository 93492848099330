import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { Routes, RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { OffsidebarComponent } from './offsidebar/offsidebar.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';
import { EditProfileComponent } from '../routes/user-settings/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from '../routes/user-settings/change-password/change-password.component';

import { SharedModule } from '../shared/shared.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MdePopoverModule } from '@material-extended/mde';
import { BlinkComponent } from '../blink.component';
import { LoaderService } from './loader/loader.service';

@NgModule({
  imports: [
    // RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    MdePopoverModule,
  ],
  providers: [UserblockService, LoaderService],
  declarations: [
    LayoutComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
    NavsearchComponent,
    OffsidebarComponent,
    FooterComponent,
    NotificationsComponent,
    BlinkComponent,
  ],
  exports: [
    LayoutComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
    NavsearchComponent,
    OffsidebarComponent,
    FooterComponent,
    NotificationsComponent,
  ],
})
export class LayoutModule {}
