import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RecoverComponent } from './recover/recover.component';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SignupComponent } from './signup/signup.component';
import { StartFreeTrialComponent } from './start-free-trial/start-free-trial.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordInvalidComponent } from './reset-password-invalid/reset-password-invalid.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { EmailVerificationSuccessComponent } from './email-verification-success/email-verification-success.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { EmailLinkExpiredComponent } from './email-link-expired/email-link-expired.component';
import { EmailLinkResendComponent } from './email-link-resend/email-link-resend.component';
import { RoleSelectionComponent } from './role-selection/role-selection.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PaymentComponent } from './payment/payment.component';

/* Use this routes definition in case you want to make them lazy-loaded */
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'signup1', component: SignupComponent },
  { path: 'signup', component: StartFreeTrialComponent },
  { path: 'page/email/success', component: EmailVerificationSuccessComponent },
  { path: 'page/email/expired', component: EmailLinkExpiredComponent },
  { path: 'page/email/resend', component: EmailLinkResendComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'page/password/forgot', component: ForgotPasswordComponent },
  { path: 'page/password/reset/:token', component: ResetPasswordComponent },
  { path: 'page/password/expired', component: ResetPasswordInvalidComponent },
  { path: 'page/password/success', component: ResetPasswordSuccessComponent },
  { path: 'page/password/success', component: ResetPasswordSuccessComponent },
  { path: 'role-selection', component: RoleSelectionComponent },
  { path: 'lock', component: LockComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '404', component: Error404Component },
  { path: '500', component: Error500Component },
  { path: 'subscription', component: SubscriptionComponent },
];

@NgModule({
  imports: [Ng2TelInputModule, SharedModule, RouterModule.forChild(routes)],
  declarations: [
    LoginComponent,
    RegisterComponent,
    RecoverComponent,
    LockComponent,
    MaintenanceComponent,
    Error404Component,
    Error500Component,
    SignupComponent,
    StartFreeTrialComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetPasswordInvalidComponent,
    ResetPasswordSuccessComponent,
    EmailLinkExpiredComponent,
    EmailLinkResendComponent,
    EmailVerificationSuccessComponent,
    RoleSelectionComponent,
    SubscriptionComponent,
    PaymentComponent,
  ],
  exports: [
    Ng2TelInputModule,
    RouterModule,
    LoginComponent,
    RegisterComponent,
    RecoverComponent,
    LockComponent,
    MaintenanceComponent,
    Error404Component,
    Error500Component,
    MatFormFieldModule,
    MatInputModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {}
