import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatSpinner } from "@angular/material/progress-spinner";

@Injectable({
  providedIn: 'root',
})

export class SpinnerService {
  private spinnerOverlayRef: OverlayRef = this.cdkSpinnerOverlay();

  constructor(private overlay: Overlay) {
  }

  /**
   * Createing an overlay
   */
  private cdkSpinnerOverlay(): OverlayRef {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    })
  }

  /**
   * Show spinner
   */
  showSpinner(): void {
    console.log("showwwwwwwww ")
    this.spinnerOverlayRef.attach(new ComponentPortal(MatSpinner))
  }

  /**
   * Stop spinner
   */
  stopSpinner(): void {
    console.log("stoppppppppppp");
    this.spinnerOverlayRef.detach();
  }
}