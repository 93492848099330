import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  /**
   * Stores the data in local storage
   * @param key to identify data
   * @param value data value
   */
  setItem(key: any, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Gets the data from local storage using key
   * @param key to identify data
   */
  getItem(key: any): any {
    return JSON.parse(localStorage.getItem(key));
  }

  /**
   * Deletes the data from local storage
   * @param key to identify data
   */
  deleteItem(key: any) {
    localStorage.removeItem(key);
  }
}
