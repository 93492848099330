import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { AuthGuardService } from './auth.guard.service';

export const routes: Routes = [
  { path: '', canActivate: [AuthGuardService], component: LoginComponent },

  {
    path: '',
    canActivate: [AuthGuardService],
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'cloud-endpoints',
        loadChildren: () =>
          import('./cloud-endpoints/cloud-endpoints.module').then(
            (m) => m.CloudEndpointsModule
          ),
      },
      {
        path: 'datamanagement',
        loadChildren: () =>
          import('./datamanagement/datamanagement.module').then(
            (m) => m.DatamanagementModule
          ),
      },
      {
        path: 'resourcemanagement',
        loadChildren: () =>
          import('./resourcemanagement/resourcemanagement.module').then(
            (m) => m.ResourcemanagementModule
          ),
      },
      {
        path: 'protocol',
        loadChildren: () =>
          import('./protocoladapters/protocoladapters.module').then(
            (m) => m.ProtocoladaptersModule
          ),
      },
      {
        path: 'data-table',
        loadChildren: () =>
          import('./data-table/data-table.module').then(
            (m) => m.DataTableModule
          ),
      },
      {
        path: 'dataflow',
        loadChildren: () =>
          import('./dataflow/dataflow.module').then((m) => m.DataflowModule),
      },
      {
        path: 'taskmanagement',
        loadChildren: () =>
          import('./task-management/task-management.module').then(
            (m) => m.TaskManagementModule
          ),
        canActivate: [AuthGuardService],
      },
      {
        path: 'routecard',
        loadChildren: () =>
          import('./route-card/route-card.module').then(
            (m) => m.RouteCardModule
          ),
        canActivate: [AuthGuardService],
      },
      {
        path: 'edge-analytics',
        loadChildren: () =>
          import('./machinevision/machinevision.module').then(
            (m) => m.MachinevisionModule
          ),
      },

      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'smart-action',
        loadChildren: () =>
          import('./smart-action/smart-action.module').then(
            (m) => m.SmartActionModule
          ),
      },
      {
        path: 'usermanagement',
        loadChildren: () =>
          import('./usermanagement/usermanagement.module').then(
            (m) => m.UsermanagementModule
          ),
      },
      {
        path: 'help-center',
        loadChildren: () =>
          import('./help-center/help-center.module').then(
            (m) => m.HelpCenterModule
          ),
      },
      {
        path: 'usersettings',
        loadChildren: () =>
          import('./user-settings/user-settings.module').then(
            (m) => m.UserSettingsModule
          ),
      },
    ],
  },

  // Not lazy-loaded routes
  { path: '404', component: LoginComponent },
  { path: '500', component: Error500Component },

  // Not found
  { path: '**', component: Error404Component },
];
