<div class="wrapper">
  <div class="block-center wd-xxl">
    <!-- START card-->
    <div class="card">
      <div class="card-header text-center bg-dark">
        <a href="#">
          <img class="rounded" src="assets/img/logo.png" alt="Image" />
        </a>
      </div>
      <div class="card-body">
        <h4 class="text-center py-2">Sign up using your email</h4>
        <form [formGroup]="registerForm" class="form-validate mb-3" role="form" name="registerForm" novalidate=""
          (submit)="registerUser(registerForm.value)">
          <!-- First name input -->
          <div formGroupName="namesGroup">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput class="form-control" id="firstName" type="text" name="firstName"
                  placeholder="Enter first name" autocomplete="off" formControlName="firstName" required="" />
                <mat-icon matSuffix>
                  <em class="fa fa-user"></em>
                </mat-icon>
                <mat-error *ngIf="
                    registerForm
                      .get('namesGroup.firstName')
                      .hasError('required') &&
                    (registerForm.get('namesGroup.firstName').dirty ||
                      registerForm.get('namesGroup.firstName').touched)
                  ">
                  First name is required
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Last name input -->
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput class="form-control border-right-0" id="lastName" type="text" name="lastName"
                  placeholder="Enter last name" autocomplete="off" formControlName="lastName" required="" />
                <mat-icon matSuffix>
                  <em class="fa fa-user"></em>
                </mat-icon>
                <mat-error *ngIf="
                    registerForm
                      .get('namesGroup.lastName')
                      .hasError('required') &&
                    (registerForm.get('namesGroup.lastName').dirty ||
                      registerForm.get('namesGroup.lastName').touched)
                  ">
                  Last name is required
                </mat-error>
              </mat-form-field>
            </div>

            <!-- User name input -->
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>User Name</mat-label>
                <input matInput class="form-control border-right-0" id="username" type="text" name="username"
                  placeholder="Enter user name" autocomplete="off" formControlName="username" required="" />
                <mat-icon matSuffix>
                  <em class="fa fa-user"></em>
                </mat-icon>
                <mat-error *ngIf="
                    registerForm
                      .get('namesGroup.username')
                      .hasError('required') &&
                    (registerForm.get('namesGroup.username').dirty ||
                      registerForm.get('namesGroup.username').touched)
                  ">
                  User name is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Email input -->
          <div class="form-group">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput class="form-control border-right-0" type="email" name="account_email"
                placeholder="Enter email" autocomplete="off" formControlName="email" required="" />
              <mat-icon matSuffix>
                <em class="fa fa-envelope"></em>
              </mat-icon>
              <mat-error *ngIf="
                  registerForm.controls['email'].hasError('required') &&
                  (registerForm.controls['email'].dirty ||
                    registerForm.controls['email'].touched)
                ">
                Email id is required
              </mat-error>
              <mat-error *ngIf="
                  registerForm.controls['email'].hasError('email') &&
                  (registerForm.controls['email'].dirty ||
                    registerForm.controls['email'].touched)
                ">
                This field must be a valid email address
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Password inputs -->
          <div formGroupName="passwordGroup">

            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput class="form-control" id="password" type="password" name="password"
                  formControlName="password" [formControl]="registerForm.get('passwordGroup.password')" />
                <mat-icon matSuffix>
                  <em class="fa fa-lock"></em>
                </mat-icon>
                <mat-error *ngIf="
                  registerForm
                    .get('passwordGroup.password')
                    .hasError('required') &&
                  (registerForm.get('passwordGroup.password').dirty ||
                    registerForm.get('passwordGroup.password').touched)
                ">
                  This field is required
                </mat-error>
                <mat-error *ngIf="
                  registerForm
                    .get('passwordGroup.password')
                    .hasError('pattern') &&
                  (registerForm.get('passwordGroup.password').dirty ||
                    registerForm.get('passwordGroup.password').touched)
                ">
                  Password must be - Minimum eight characters, at least one
                  uppercase letter, one lowercase letter, one number and one
                  special character
                </mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>Retype Password</mat-label>
                <input matInput class="form-control" type="password" name="confirmPassword"
                  formControlName="confirmPassword" [formControl]="
                    registerForm.get('passwordGroup.confirmPassword')
                  " />
                <mat-icon matSuffix>
                  <em class="fa fa-lock"></em>
                </mat-icon>
                <mat-error *ngIf="
                  registerForm
                    .get('passwordGroup.confirmPassword')
                    .hasError('required') &&
                  (registerForm.get('passwordGroup.confirmPassword').dirty ||
                    registerForm.get('passwordGroup.confirmPassword').touched)
                ">
                  This field is required
                </mat-error>
                <mat-error *ngIf="
                  registerForm
                    .get('passwordGroup.confirmPassword')
                    .hasError('equalTo')
                ">
                  Password does Not match
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <button class="btn btn-block btn-primary mt-3" type="submit">
            Create account
          </button>
        </form>
        <p class="pt-3 text-center">Have an account?</p>
        <a class="btn btn-block btn-secondary" [routerLink]="'/login'">Sign In</a>
      </div>
    </div>
  </div>
</div>