<!DOCTYPE html>
<html>

<div class="row justify-content-center align-items-center h-100">
    <script src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.13/lottie.min.js"
        integrity="sha512-srGxQe2w7s50+5/nNgEVKYtBm15zRylJwdjxYnGEZr3mmHFJKFjA/ImA2OKizVzoIDX8XISMHDI1+az9pnumbQ=="
        crossorigin="anonymous" referrerpolicy="no-referrer"></script>
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    <div class="container" id="svg">
        <div class="card-shadow">
            <div bgcolor=" #ffffff" align="center" valign="top">
                <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_rovf9gzu.json"
                    background="transparent" width="100px" height="100px" speed="2" loop autoplay>
                </lottie-player>
                <h3 style="font-size: 24px;font-weight: bold;color: #07509d;letter-spacing: 0.3px;margin: 2;">
                    Congratulations!</h3>
                <h3 style="margin: 0;text-align: center;">Email address successfully verified, please
                    login
                    to continue.</h3>
                <button mat-raised-button class="btn btn-primary btn-lg mt-2" (click)="navigateToLogin()">Login</button>
            </div>
        </div>
    </div>
</div>

</html>