import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-email-verification-success',
  templateUrl: './email-verification-success.component.html',
  styleUrls: ['./email-verification-success.component.scss'],
})
export class EmailVerificationSuccessComponent implements OnInit {
  constructor(private _route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}
  navigateToLogin() {
    this.router.navigate(['../login']);
  }
}
