<section class="vh-100">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100" style="flex-direction: column;">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div style="text-align:center">
          <img class="img-fluid" src="../../../../assets/img/logo2.svg" alt="App Logo" />
          <h3 class="mb-4 mt-1" style="font-size: 24px;
           font-weight: 500;color: #463b3b;
           letter-spacing: 0.3px;">Reset Your Password</h3>
        </div>
        <form [formGroup]="valForm" role="form" name="valForm" novalidate=""
          (submit)="submitForm($event, valForm.value)">

          <ng-container *ngIf="roleOfUser == 'operator'; else elseTemplate">

            <div class="col-md-12 groupp">
              <mat-form-field appearance="outline" style="display:block;">
                <mat-label>New PIN</mat-label>
                <input matInput id="exampleInputEmail1" [type]="newHide ? 'password' : 'number'" placeholder="New PIN"
                  autocomplete="off" formControlName="newPassword" minlength="4" maxlength="4" required />
                <button mat-icon-button matSuffix (click)="newHide = !newHide" type="button">
                  <mat-icon>{{newHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>

              </mat-form-field>
              <mat-error class="ml-3"
                *ngIf="valForm.controls['newPassword'].touched &&  valForm.controls['newPassword'].hasError('required')">
                Please enter any 4 digit PIN.
              </mat-error>
            </div>
            <div class="col-md-12 groupp">
              <mat-form-field appearance="outline" style="display:block;margin-bottom: 7px;">
                <mat-label>Verify PIN</mat-label>
                <input matInput id="exampleInputPassword1" [type]="verifyHide ? 'password' : 'text'" name="password"
                  placeholder="Verify PIN" formControlName="verifyPassword" minlength="4" maxlength="4" required />
                <button mat-icon-button matSuffix (click)="verifyHide = !verifyHide" type="button">
                  <mat-icon>{{verifyHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <mat-error class="ml-3"
                *ngIf="valForm.controls['newPassword'].valid && valForm.controls['verifyPassword'].valid && PasswordMatch()">
                PIN does not match.
              </mat-error>
            </div>
          </ng-container>
          <ng-template #elseTemplate>


            <div class="col-md-12 groupp">
              <mat-form-field appearance="outline" style="display:block;">
                <mat-label>New password</mat-label>
                <input matInput id="exampleInputEmail1" [type]="newHide ? 'password' : 'text'" name="newPassword"
                  placeholder="New password" (keyup)='RegexCheck()' autocomplete="off" formControlName="newPassword"
                  minlength="8" maxlength="40" required="" />
                <button mat-icon-button matSuffix (click)="newHide = !newHide" type="button">
                  <mat-icon>{{newHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <div *ngIf="visitedflag" style="display: block;">
                <mat-error class="ml-3" *ngIf="valForm.get('newPassword').hasError('minlength')">
                  The password must be at least 8 characters long.
                </mat-error>
                <br *ngIf="valForm.get('newPassword').hasError('minlength')">
                <mat-error class="ml-3" *ngIf="numberflag==false ">
                  The password must contain at least one number.
                </mat-error>
                <br *ngIf="numberflag==false">
                <mat-error class="ml-3" *ngIf="lowerflag==false">
                  The password must contain at least one lowercase letter.
                </mat-error>
                <br *ngIf="lowerflag==false">
                <mat-error class="ml-3" *ngIf="upperflag==false">
                  The password must contain at least one uppercase letter.
                </mat-error>
                <br *ngIf="upperflag==false">
                <mat-error class="ml-3" *ngIf="specialcharflag==false">
                  The password must contain at least one special character.
                </mat-error>
              </div>
            </div>
            <div class="col-md-12 groupp">
              <mat-form-field appearance="outline" style="display:block;margin-bottom: 7px;">
                <mat-label>Verify password</mat-label>
                <input matInput id="exampleInputPassword1" [type]="verifyHide ? 'password' : 'text'" name="password"
                  placeholder="Verify password" formControlName="verifyPassword" maxlength="40" required="" />
                <button mat-icon-button matSuffix (click)="verifyHide = !verifyHide" type="button">
                  <mat-icon>{{verifyHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <mat-error class="ml-3" *ngIf="valForm.controls['verifyPassword'].valid && PasswordMatch()">
                Password does not match.
              </mat-error>
            </div>

          </ng-template>
          <div class="col-md-12">
            <button
              [disabled]="!valForm.valid || PasswordMatch() || numberflag==false || lowerflag==false || upperflag==false || specialcharflag==false"
              mat-raised-button class="btn btn-primary btn-lg" type="submit" style="width: 100%;">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>