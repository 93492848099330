import { RegisterService } from './register.service';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  passwordForm: FormGroup;
  namesForm: FormGroup;

  constructor(
    public settings: SettingsService,
    fb: FormBuilder,
    private registerService: RegisterService,
    private router: Router
  ) {
    let password = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9@#$]{10,}$'),
      ])
    );
    let certainPassword = new FormControl('', [
      Validators.required,
      CustomValidators.equalTo(password),
    ]);

    this.namesForm = fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      username: [null, Validators.required],
    });

    this.passwordForm = fb.group({
      password: password,
      confirmPassword: certainPassword,
    });

    this.registerForm = fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      passwordGroup: this.passwordForm,
      namesGroup: this.namesForm,
    });
  }

  registerUser(value: any): void {
    for (let c in this.registerForm.controls) {
      this.registerForm.controls[c].markAsTouched();
    }
    for (let c in this.passwordForm.controls) {
      this.passwordForm.controls[c].markAsTouched();
    }

    if (this.registerForm.valid) {
      console.log('Valid!');
      console.log(value);
      const userData = {
        email: value.email,
        ...value.passwordGroup,
        ...value.namesGroup,
      };
      this.registerService.registerUser(userData).subscribe((res) => {
        if (res) {
          this.router.navigateByUrl('/login');
        }
      });
    }
  }

  ngOnInit() {}
}
