import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { EMPTY, NEVER, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NotificationAPIService } from '../notifications/services/notification-api.service';
import { SharedService } from 'src/app/shared.service';
@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private _sanitizer: DomSanitizer,
    private _toaster: ToastrService,
    public notificationAPI: NotificationAPIService,
    private sharedService: SharedService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);
    var newReq: any = req;
    // var sanitizedObj = this.sanitizeRequest(req.body);
    if (this.checkScriptOrSymbolInjection(req.body)) {
      this._toaster['error'](
        'Error occurred, This request contains malicious code.',
        'IoTSense'
      );
      return EMPTY;
    }
    // Creating new request using sanitizedObj
    // newReq = req.clone({ body: sanitizedObj });

    return next.handle(newReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          // the `error` property of the HttpErrorResponse instance
          // contains the HTTP response body
          if (error.status == 403) {
            this.notificationAPI.getNewNotification([]).subscribe(
              (data: any) => {},
              (err) => {
                if (err.status == 403) {
                  if (!window['isPasswordChanged']) {
                    this._toaster.info(
                      'This session has expired, login again.',
                      'IoTSense',
                      {
                        timeOut: 3000,
                      }
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  } else {
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  }
                }
              }
            );
          }
          return throwError(error);
        }
      }),
      finalize(() => {
        this.loaderService.isLoading.next(false);
      })
    );
  }
  /* This is used for recursively sanitizing data */
  sanitizeRequest(object) {
    var isArray = Array.isArray(object);
    for (let key in object) {
      let value = object[key];
      let newKey: any = key;
      if (!isArray) {
        // if it is an object
        delete object[key]; // firstly remove the key
        newKey = this.getSanitizeValue(key); // and then sanitizing key
      }
      let newValue = value;
      if (typeof value != 'object' && typeof value == 'string') {
        newValue = this.getSanitizeValue(value); // sanitizing as it is not object
      } else {
        // if it is an object, recursively sanitizing it
        newValue = this.sanitizeRequest(value);
      }
      object[newKey] = newValue;
    }
    return object;
  }
  /* This is use to sanitize value  */
  getSanitizeValue(input: any): SafeHtml {
    if (
      input.includes('<script>') ||
      input.includes('</script>') ||
      input.includes('<img ') ||
      input.includes('<a href') ||
      input.includes('</a>')
    ) {
      this._toaster['error'](
        'Error occurred, This request contains malicious code.',
        'IoTSense'
      );
      return EMPTY;
    } else {
      return input;
    }
  }
  //    return this._sanitizer.sanitize(SecurityContext.HTML, input);
  /**
   * Perform recursive operation to check the script injection in the req body
   * @param request body
   * @returns
   */
  checkScriptOrSymbolInjection(request: any): boolean {
    let result = false;
    if (request && (request?.length || Object.keys(request)?.length)) {
      switch (request.constructor) {
        // string condition
        case ''.constructor:
          if (
            request.includes('<script>') ||
            request.includes('</script>') ||
            request.includes('<img ') ||
            request.includes('<a href=') ||
            request.includes('<a>')
          ) {
            console.log(
              'return symbol warning due to symbol in this : ',
              request
            );
            result = true;
          }
          break;
        // list condition
        case [].constructor:
          request?.forEach((element: any) => {
            if (this.checkScriptOrSymbolInjection(element)) {
              return (result = true);
            }
          });
          break;
        // object condition
        case {}.constructor:
          Object.keys(request).forEach((key) => {
            if (this.checkScriptOrSymbolInjection(request[key])) {
              return (result = true);
            }
          });
          break;
      }
    }
    return result;
  }
}
