import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { gateway } from '../../../../environments/gateway';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StartFreeTrialService {
  header = new Headers({ 'Content-Type': 'application/json' });
  private url = '';

  constructor(private http: HttpClient) {
    if (gateway.apiUrl) {
      this.url = gateway.apiUrl;
    }
  }

  doSignup(signupUserData) {
    //let url = '/api/auth/signin';
    return this.http
      .post(this.url + '/api/auth/start-free-trial', signupUserData)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
