<div class="row">
    <div class="col-md-11" style="text-align: center;">
        <h3 class="headText1" style="margin-bottom: 0px !important;">Switch Owner</h3>
    </div>
    <div class="col-md-1">
        <button type="button" class="close" mat-dialog-close>
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<hr>
<div mat-dialog-body>
    <div class="mb-3">
        Current Owner of {{data.name}} is {{ownerMap?.displayName}}
    </div>
    <mat-form-field appearance="outline" style="display: block;">
        <mat-label>Change Owner</mat-label>
        <mat-select #owner="ngModel" [(ngModel)]="selectedOwner" disableOptionCentering required>
            <mat-option *ngFor="let user of users" [value]="user._id">
                {{user?.displayName}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="!owner.valid">Owner is required.</mat-error>
    </mat-form-field>
</div>
<div class="modal-footer"
    style="text-align: center;display: block;border-top: none;padding-bottom: 0px !important;padding-top: 0%;">
    <button [disabled]="!owner.valid" mat-raised-button class="btn btn-primary btn-lg" mat-dialog-close
        (click)="updateOwner(selectedOwner)">Update</button>
</div>