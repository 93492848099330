import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  firstFormGroup: FormGroup;
  toaster: any;

  constructor(
    public settings: SettingsService,
    private _snackBar: MatSnackBar,
    private router: Router,
    fb: FormBuilder,
    private _loginService: LoginService,
    private _toaster: ToastrService
  ) {
    this.firstFormGroup = fb.group({
      email: [null, Validators.required],
    });
  }
  changepass(val) {
    this.toaster = {
      type: 'success',
      title: 'Title',
      text: 'Message',
    };
    this._toaster['info']('Sending reset link.', 'IoTSense');
    this._loginService.forgetpass(val).subscribe(
      (data) => {
        this.toaster.title = 'IoTSense';
        this.toaster.text = 'Password reset link sent to your email.';
        this._toaster['success'](this.toaster.text, this.toaster.title);
        this.router.navigate(['login']);
      },
      (error) => {
        this._snackBar.open(error.error.message + '.', 'Dismiss', {
          duration: 100000,
        });
      }
    );
  }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.firstFormGroup.controls) {
      this.firstFormGroup.controls[c].markAsTouched();
    }
    if (this.firstFormGroup.valid) {
      this.changepass(value);
    } else {
      this._snackBar.open('Email is required.', 'Dismiss', {
        duration: 3000,
      });
    }
  }
  onBlur(e) {
    if (e.target.value == '') {
      this._snackBar.open('Email is required.', 'Dismiss', {
        duration: 3000,
      });
    }
  }
  ngOnInit() {}
}
