import { M } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class MenuService {
  menuItems: Array<any> = [];
  displaymenuItems: Array<any> = [];

  user: any;
  constructor(private cookieService: CookieService) { }

  addMenu(
    items: Array<{
      text: string;
      heading?: boolean;
      link?: string; // internal route links
      elink?: string; // used only for external links
      target?: string; // anchor target="_blank|_self|_parent|_top|framename"
      icon?: string;
      alert?: string;
      submenu?: Array<any>;
    }>
  ) {
    items.forEach((item) => {
      // console.log("\n  \n  ~🚀 items.forEach \n  ~🚀 item", item)
      this.menuItems.push(item);
    });
  }

  getMenu() {
    this.user = window['user'];
    var unEditedItems = this.menuItems;
    // console.log("\n  \n  ~🚀 getMenu \n  ~🚀 unEditedItems", unEditedItems)
    this.displaymenuItems = [];
    unEditedItems.forEach((item) => {
      if (item.allowedRoles != undefined || item.submenu != undefined) {
        if (item.submenu != undefined) {
          var newSubmenu = [];
          var absentCount = 0;
          item.submenu.forEach((ele) => {
            // console.log("\n  \n  ~🚀 item.submenu.forEach \n  ~🚀 ele", ele)
            ele['is_invited'] = this.user.is_Invited ? true : false;
            if (ele.is_invited) {
              if (
                this.user.subId.planType == 'Free' ||
                this.user.subId.planType == 'Basic'
              ) {
                if (
                  ele.text == 'Machine Groups' ||
                  ele.text == 'Smart Security'
                ) {
                  ele['is_premium'] = true;
                }
              } else if (this.user.subId.planType == 'Pro')
                if (
                  ele.text == 'Machine Groups' ||
                  ele.text == 'Commands' ||
                  ele.text == 'Smart Security'
                ) {
                  ele['is_premium'] = false;
                }
            }
            if (ele.allowedRoles.indexOf(this.user.roles[0]) > -1) {
              newSubmenu.push(ele);
            }
          });


          if (
            this.user.is_Invited &&
            (item.text == 'Route Card' || item.text == 'Task Management' || item.text == 'Dataflow Management')
          ) {
            // item['is_custom'] = true;
            item['is_premium'] = true;
            item.submenu = [];
            item.submenu = newSubmenu;
            if (item.submenu.length != 0) {
              this.displaymenuItems.push(item);
            }
          } else {
            item.submenu = [];
            item.submenu = newSubmenu;
            if (item.submenu.length != 0) {
              this.displaymenuItems.push(item);
            }
          }
        } else {
          item['is_invited'] = this.user?.is_Invited ? true : false;
          if (item.is_invited) {
            if (
              this.user.subId.planType == 'Free' ||
              this.user.subId.planType == 'Basic'
            ) {
              if (
                // item.text == 'Cloud Endpoints' ||
                item.text == 'User Management' ||
                item.text == 'Protocol Adapters'
              ) {
                item['is_premium'] = true;
              }
            } else if (this.user.subId.planType == 'Pro') {
              if (
                item.text == 'Cloud Endpoints' ||
                item.text == 'User Management' ||
                item.text == 'Protocol Adapters'
              ) {
                item['is_premium'] = false;
              } else if (
                item.text == 'Route Card' ||
                item.text == 'Task Management' ||
                item.text == 'Machine Vision'
              ) {
                item['is_custom'] = true;
              }
            }
          }
          if (item.allowedRoles.indexOf(this.user.roles[0]) > -1) {
            this.displaymenuItems.push(item);
          }
        }
      } else {
        item['is_invited'] = this.user?.is_Invited ? true : false;
        if (item.is_invited) {
          if (
            item.text == 'Cloud Endpoints' ||
            item.text == 'Machine Vision' ||
            item.text == 'Protocol Adapters' ||
            item.text == 'Route Card' ||
            item.text == 'Task Management' ||
            item.text == 'User Management'

          ) {
            item['is_premium'] = true;
          }
        }
        this.displaymenuItems.push(item);
      }
    });


    if (this.user.layout?.datatableNameMenu?.length) {

      this.user.layout?.datatableNameMenu.map((y) => {
        if (y.isChecked == true) {
          let obj = {
            "text": y.name,
            "link": `/data-table/view/${y._id}`,
            "icon": y.icon,
            "allowedRoles": [
              "sudo"
            ],
            "is_invited": false
          };
          this.displaymenuItems.splice(9, 0, obj);//add datatable name to sidebar menu
        }

      });

    }

    return this.displaymenuItems;
  }
}
