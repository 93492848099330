import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @Input() selectedPlan: any;

  user: any;

  
  constructor() { }

  ngOnInit(): void {
   
  
   
      this.user = window['user'];
  
  }

}
