<div class="mat-typography">
  <div class="col-md-8 offset-md-2">
    <div style="text-align: center;">
      <img class="img-fluid" src="../../../../assets/img/logo2.svg" alt="App Logo" />
    </div>
    <mat-horizontal-stepper [linear]="true" #stepper labelPosition="bottom">
      <ng-template matStepperIcon="edit" let-index="index">
        {{index + 1}}
      </ng-template>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <div class="textSignin" style="margin-top: 3%;">
            <h5>Hello!</h5>
            <h5>Welcome To <b style="color: #28519d;">IoTSense</b></h5>
            <h2>To get started, click on next button.</h2>
          </div>

          <div class="col-md-12">
            <button mat-raised-button class="btn btn-primary btn-lg" type="submit" style="width: 46%;
            margin-left: 27%;margin-top: 10px;" matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <div class="col-md-6 offset-md-3">
            <div class="textSignin">
            </div>
            <div class="col-md-12 groupp">
              <label class="labelClass">I am</label>
              <mat-form-field appearance="outline" style="display: block;">
                <mat-select formControlName="role" disableOptionCentering name="role" required>
                  <mat-option value="" selected disabled>
                    Select option
                  </mat-option>
                  <mat-option value="a CIO / CTO">
                    a CIO / CTO
                  </mat-option>
                  <mat-option value="an Engineer">
                    an Engineer
                  </mat-option>
                  <mat-option value="a Head / VP / Director">
                    a Head / VP / Director
                  </mat-option>
                  <mat-option value="in IT">
                    in IT
                  </mat-option>
                  <mat-option value="an Operations / Plant Manager/ Site Manager">
                    an Operations / Plant Manager / Site Manager
                  </mat-option>
                  <mat-option value="a Quality expert">
                    a Quality expert
                  </mat-option>
                  <mat-option value="Other">
                    Other
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="secondFormGroup.controls['role'].hasError('required') && (secondFormGroup.controls['role'].dirty || secondFormGroup.controls['role'].touched)">
                  This field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 groupp" *ngIf="secondFormGroup.value.role == 'Other'">
              <mat-form-field appearance="outline" style="display: block;">
                <mat-label>Other</mat-label>
                <textarea matInput id="otherrole" formControlName="otherrole" name="otherrole" rows="3" maxlength="256"
                placeholder="Details..." cols="30" required></textarea>
              </mat-form-field>
            </div>
            <div class="col-md-12 groupp">
              <label class="labelClass">Looking to</label>
              <mat-form-field appearance="outline" style="display: block;">
                <mat-select formControlName="lookingfor" disableOptionCentering name="lookingfor" required>
                  <mat-option value="" selected disabled>
                    Select option
                  </mat-option>
                  <mat-option value="Faster decisions">
                    Faster decisions
                  </mat-option>
                  <mat-option value="Implement ready to use IoT App.">
                    Implement ready to use IoT App
                  </mat-option>
                  <mat-option value="Learn more about IIot & Industry 4.0">
                    Learn more about IIoT & Industry 4.0
                  </mat-option>
                  <mat-option value="Make better and faster decisions">
                    Make better and faster decisions
                  </mat-option>
                  <mat-option value="Optimize production process">
                    Optimize production process
                  </mat-option>
                  <mat-option value="Reduce scrap/ waste / Energy Consumption">
                    Reduce scrap / waste / Energy Consumption
                  </mat-option>
                  <mat-option value="Standarlize processes and Knowledge">
                    Standardize processes and Knowledge
                  </mat-option>
                  <mat-option value="Other">
                    Other
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="secondFormGroup.controls['lookingfor'].hasError('required') && (secondFormGroup.controls['lookingfor'].dirty || secondFormGroup.controls['lookingfor'].touched)">
                  This field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 groupp" *ngIf="secondFormGroup.value.lookingfor == 'Other'">
              <mat-form-field appearance="outline" style="display: block;">
                <mat-label>Other</mat-label>
                <textarea matInput id="otherlookingfor" formControlName="otherlookingfor" name="otherlookingfor" rows="3" maxlength="256"
                  placeholder="Details..." cols="30" required></textarea>
              </mat-form-field>
            </div>
           </div>
          
          <div class="col-md-12">
            <button mat-raised-button class="btn btn-primary btn-lg" type="submit"
              style="width: 46%;margin-left:27%;margin-top: 8px;" matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">
          <div class="textSignin">
            <h5>Choose the use case that best applies to you.</h5>
            <h2 style="margin-bottom: 8px !important;">
              We will streamline your demo data accordingly.
            </h2>
          </div>
          <div class="col-md-10 offset-md-1">
            
            <div class="col-md-12 radio-groupp">
     
              <label class="level">
                <input type="radio" name="useCase" value="manufacturing" formControlName="useCase" />
                <div class="back-end box">
                  <span>
                    <img src="../../../../assets/img/free-trial/manufacturing.svg" alt="App Logo" />
                    <h2>Manufacturing</h2>
                    Write better. Think more clearly. Stay organized
                  </span>
                </div>
              </label>
              <label class="level">
                <input type="radio" name="useCase" value="construction" formControlName="useCase" />
                <div class="front-end box">
                  <span>
                    <img src="../../../../assets/img/free-trial/construction.svg" alt="App Logo" />
                    <h2>Construction</h2>
                    Wikis, docs, task and projects all in one place
                  </span>
                </div>
              </label>
              <label class="level">
                <input type="radio" name="useCase" value="fleet" formControlName="useCase" />
                <div class="front-end box">
                  <span>
                    <img src="../../../../assets/img/free-trial/fleet.svg" alt="App Logo" />
                    <h2>Fleet</h2>
                    Wikis, docs, task and projects all in one place
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="col-md-8 offset-md-2">
           <div class="col-md-12">
            <button mat-raised-button class="btn btn-primary btn-lg" type="submit"
              style="margin-top: 8px;width:48%" (click)="generatedata(stepper)">Generate Demo Data</button>
              <button mat-raised-button class="btn-lg" type="submit"
              style="margin-left: 4%;margin-top: 8px;border:1px solid #28519d;color:#28519d;width:48%" matStepperNext>Skip to Build From Scratch</button>
         </div>
        </div>
        </form>
      </mat-step>
      <mat-step>
        <div style="margin-top: 20px;
                    color: #fff;text-align: center;">
          <div class="circle">
            <mat-icon style="font-size: 23px;">done</mat-icon>
          </div>
        </div>
        <div class="textSignin" style="margin-top: 20px;">
          <h5>Congratulation, {{userName}}!</h5>
          <h2>You have completed your setup, you can start using the IoTSense.</h2>
        </div>
        <div class="col-md-12">
          <button mat-raised-button class="btn btn-primary btn-lg" type="submit"
            style="width: 46%;margin-left:27%;margin-top: 8px;" (click)="upadtefun()">Launch IoTSense</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>