<div class="mt-5">
    <h2 class="text-app-orange">Great choice, {{ user.firstName | titlecase }}!</h2>
    <h4 class="font-weight-normal">It's almost done.</h4>
  
    <div class="row mt-5">
      <div class="col-md-7"></div>
      <div class="col-md-5">
        <mat-card>
          <mat-card-header class="bg-gray-light px-3 pt-3">
            <div mat-card-avatar class="example-header-image"><mat-icon>shopping_cart</mat-icon></div>
            <mat-card-title class="font-weight-bold">Order Summary</mat-card-title>
          </mat-card-header>
          <mat-card-content class="mt-4">
            <div class="d-flex">
              <div>
                <h4 class="font-weight-normal">{{ selectedPlan.type | titlecase }}</h4>
                <ul>
                  <li class="text-dark" *ngFor="let item of selectedPlan.benefits">{{ item }}</li>
                </ul>
              </div>
              <div class="ml-auto d-flex">
                <mat-icon>attach_money</mat-icon>
                <h4 class="font-weight-normal mt-1">{{ selectedPlan.price }}</h4>
              </div>
            </div>
            <hr />
            <div class="text-right">
              <h4>
                Total - <mat-icon style="vertical-align: bottom">attach_money</mat-icon
                >{{ selectedPlan.price }}
              </h4>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
