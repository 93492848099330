import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { gateway } from 'src/environments/gateway';
import { Component, OnInit } from '@angular/core';
// import { FileUploadService } from '../file-upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AlertConfigurationService {
  private url = '';
  createalert: any;

  constructor(private http: HttpClient) {
    if (gateway.apiUrl) {
      this.url = gateway.apiUrl;
    }
  }

  getUploadedFiles() {
    return this.http
      .get(this.url + '/api/settings/alertconfig', { withCredentials: true })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }

  uploadaudio(data) {
    return this.http
      .post(this.url + '/api/settings/alertconfig', data, {
        withCredentials: true,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  updatealertConfig(data) {
    return this.http
      .put(this.url + '/api/settings/alertconfig', data, {
        withCredentials: true,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
  readAlertConfig() {
    return this.http
      .get(this.url + '/api/settings/read/alertconfig', {
        withCredentials: true,
      })
      .pipe(
        map((list) => {
          return list;
        })
      );
  }
}

function getalertConfig() {
  throw new Error('Function not implemented.');
}
function data(arg0: string, data: any, arg2: { withCredentials: true }) {
  throw new Error('Function not implemented.');
}
