import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { StartFreeTrialService } from './start-free-trial.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from './custom-validators';
const swal = require('sweetalert');

export interface IPasswordProfile {
  minChar: number;
  numbers: number;
  upperCase: number;
  lowerCase: number;
  specialChar: number;
  maxPasswordAge: number;
  uniqueness: number;
}

@Component({
  selector: 'app-start-free-trial',
  templateUrl: './start-free-trial.component.html',
  styleUrls: ['./start-free-trial.component.scss'],
})
export class StartFreeTrialComponent implements OnInit {
  registrationForm: FormGroup;
  submitted = false;
  hide = true;
  confirmaPasswordHide = true;
  hidePhoneLabel = false;

  countryCode = '+91';
  countryName = 'India (भारत)';
  public passwordForm: FormGroup;

  password = true;
  confirm_password = true;
  paswdProfile: IPasswordProfile = {
    minChar: 8,
    numbers: 1,
    upperCase: 1,
    lowerCase: 1,
    specialChar: 1,
    maxPasswordAge: 90,
    uniqueness: 5,
  };
  account_validation_messages = {
    firstname: [
      { type: 'required', message: 'First name is required' },
      {
        type: 'maxlength',
        message: 'First name can not be more than 50 characters long',
      },
      { type: 'pattern', message: 'First name must contain only letters' },
    ],
    lastname: [
      { type: 'required', message: 'Last name is required' },
      {
        type: 'maxlength',
        message: 'Last name cannot be more than 50 characters long',
      },
      { type: 'pattern', message: 'Last name must contain only letters' },
    ],
    username: [
      { type: 'required', message: 'Username is required' },
      {
        type: 'minlength',
        message: 'Username must be at least 5 characters long',
      },
      {
        type: 'maxlength',
        message: 'Username cannot be more than 25 characters long',
      },
    ],
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' },
    ],
    confirm_password: [
      { type: 'required', message: 'Confirm password is required' },
      { type: 'areEqual', message: 'Pasword mismatch' },
    ],
    password: [
      { type: 'required', message: 'Pasword is required' },
      {
        type: 'minlength',
        message: 'Password must be at least 5 characters long',
      },
      {
        type: 'pattern',
        message:
          'Your password must containt at least one uppercase, one lowercase, and one number',
      },
    ],
  };

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private router: Router,
    private signup: StartFreeTrialService
  ) {
    this.passwordForm = this.createPasswordForm();
  }

  ngOnInit(): void {
    this.registrationForm = this._formBuilder.group({
      firstname: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          ,
          Validators.pattern('^[a-zA-Z]+'),
          Validators.maxLength(50),
        ])
      ),
      lastname: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          ,
          Validators.pattern('^[a-zA-Z]+'),
          Validators.maxLength(50),
        ])
      ),
      username: new FormControl(
        '',
        Validators.compose([
          Validators.maxLength(25),
          Validators.minLength(5),
          Validators.required,
        ])
      ),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ])
      ),
      phoneNumber: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(10)])
      ),
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registrationForm.controls;
  }
  numericOnly(event) {
    this.hidePhoneLabel = true;
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  onCountryChange(event) {
    let countryCodePrefix = '+';
    this.countryCode = countryCodePrefix.concat(event.dialCode);
    this.countryName = event.name;
  }

  submitForm() {
    const {
      firstname,
      lastname,
      username,
      email,
      password,
      cpassword,
      phoneNumber,
    } = this.registrationForm.value;
    let signupData = {
      firstName: this.registrationForm.value.firstname,
      lastName: this.registrationForm.value.lastname,
      username: this.registrationForm.value.username,
      email: this.registrationForm.value.email,
      password: this.passwordForm.value.password,
      cpassword: this.passwordForm.value.confirmPassword,
      phone: {
        countryCode: this.countryCode,
        phone: this.registrationForm.value.phoneNumber,
        countryName: this.countryName,
      },
    };
    this.signup.doSignup(signupData).subscribe({
      next: (result) => {
        swal({
          title: 'Your account created successfully!',
          text: 'Please verify your email to login!',
          icon: 'success',
        });
        this.router.navigate(['login']); //navigate to login page or componenent
      },
      error: (responce: HttpErrorResponse) => {
        let errorMsg = responce.error.error;
        this._snackBar.open(errorMsg + '.', 'Dismiss', {
          duration: 3000,
        });
      },
    });
  }

  createPasswordForm(): FormGroup {
    return this._formBuilder.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(
              /[0-9]/g,
              this.paswdProfile.numbers,
              {
                hasNumber: true,
              }
            ),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(
              /[A-Z]/g,
              this.paswdProfile.upperCase,
              {
                hasCapitalCase: true,
              }
            ),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(
              /[a-z]/g,
              this.paswdProfile.lowerCase,
              {
                hasSmallCase: true,
              }
            ),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g,
              this.paswdProfile.specialChar,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(this.paswdProfile.minChar),
          ]),
        ],
        confirmPassword: [null, Validators.compose([Validators.required])],
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator,
      }
    );
  }
}
