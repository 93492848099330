import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-link-expired',
  templateUrl: './email-link-expired.component.html',
  styleUrls: ['./email-link-expired.component.scss']
})
export class EmailLinkExpiredComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
