import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThingService } from '../services/thing.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-switch-owner-thing',
  templateUrl: './switch-owner-thing.component.html',
  styleUrls: ['./switch-owner-thing.component.scss'],
})
export class SwitchOwnerThingComponent implements OnInit {
  users;
  selectedOwner;
  ownerMap;
  device;
  toaster: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private ts: ThingService
  ) {
    this.device = data;
  }

  ngOnInit(): void {
    this.toaster = {
      type: 'success',
      title: 'IoTSense',
      text: 'Message',
    };

    this.ts.getOwner(this.device.owner).subscribe((owner: any) => {
      this.ownerMap = owner;
    });
    this.ts.getUsersList().subscribe((userList: any) => {
      this.users = userList;
    });
  }
  updateOwner(newOwner) {
    this.data.owner = newOwner;
    this.ts.editThing(this.data).subscribe(
      (data: any) => {},
      (err) => {}
    );

    this.toaster.type = 'info';
    this.toaster.text =
      'New owner for ' + this.data.name + ' has been updated .';

    this.toastr[this.toaster.type](this.toaster.text, this.toaster.title);
  }
}
