import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/observable/forkJoin';
import { gateway } from "../../../../environments/gateway";
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DemoDataService {
  private url = "";
  constructor(private http: HttpClient) {
    if (gateway.apiUrl) {
      this.url = gateway.apiUrl
    };
   }

   getpurgeConfig() {
    return this.http.get(this.url + '/api/settings/purge', { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }

  getAdminuser() {
    return this.http.get(this.url + '/api/users', { withCredentials: true }).pipe(map(list => {
      return list;
    }));
  }

  savedemodata(data) {
    return this.http.post(this.url + '/api/settings/demodata',data, { withCredentials: true ,responseType: 'text'}).pipe(map(list => {
      return list;
    }));
  }
  saveConstructiondemodata(data) {
    return this.http.post(this.url + '/api/settings/constructiondemodata',data, { withCredentials: true ,responseType: 'text'}).pipe(map(list => {
      return list;
    }));
  }
  generateFleetDemoData(data) {
    return this.http.post(this.url + '/api/settings/demodata/fleet-tracking',data, { withCredentials: true ,responseType: 'text'}).pipe(map(list => {
      return list;
    }));
  }
}
