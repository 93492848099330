import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { DemoDataService } from '../../settings/services/demo-data.service';
import { LoginService } from '../login/login.service';
const swal = require('sweetalert');

@Component({
  selector: 'app-role-selection',
  templateUrl: './role-selection.component.html',
  styleUrls: ['./role-selection.component.scss'],
})
export class RoleSelectionComponent implements OnInit {
  isLinear = false;
  devicename;
  errordevice: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  selectedIndex: number = 0;
  userId: any;
  flag: boolean = false;
  userName: any;
  demodataflag: boolean = false;

  constructor(
    private router: Router,
    private dataService: DemoDataService,
    private cookies: CookieService,
    private fb: FormBuilder,
    private _toaster: ToastrService,
    private _loginService: LoginService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this.fb.group({
      // displayname: ['', Validators.required]
    });
    this.secondFormGroup = this.fb.group({
      role: ['', Validators.required],
      lookingfor: ['', Validators.required],
      otherrole: [],
      otherlookingfor: [''],
    });
    this.thirdFormGroup = this.fb.group({
      useCase: '',
    });
    this.userId = window['user']._id;
    this.userName = window['user'].displayName;

    // Conditionally adding validation for control
    this.secondFormGroup.get('role').valueChanges.subscribe((val) => {
      if (val == 'Other') {
        this.secondFormGroup.controls['otherrole'].setValidators([
          Validators.required,
        ]);
      } else {
        this.secondFormGroup.controls['otherrole'].clearValidators();
      }
      this.secondFormGroup.controls['otherrole'].updateValueAndValidity();
    });
    this.secondFormGroup.get('lookingfor').valueChanges.subscribe((val) => {
      if (val == 'Other') {
        this.secondFormGroup.controls['otherlookingfor'].setValidators(
          Validators.required
        );
      } else {
        this.secondFormGroup.controls['otherlookingfor'].clearValidators();
      }
      this.secondFormGroup.controls['otherlookingfor'].updateValueAndValidity();
    });
  }
  generatedata(stepper: MatStepper) {
    if (this.thirdFormGroup.value.useCase == '') {
      this._toaster['error']('Please select atleast one use case.', 'IoTSense');
    } else {
      this.devicename = 'ABCK';
      this.errordevice = null;
      if (this.thirdFormGroup.value.useCase == 'manufacturing') {
        let devices = {
          deviceName: this.devicename,
        };

        this.dataService.savedemodata(devices).subscribe(
          (response) => {
            stepper.next();
            this._toaster['success'](
              'Generating manufacturing data completed.',
              'IoTSense'
            );
          },
          (err) => {
            this.errordevice = err.error.message;
            this._toaster['error'](
              'Error in generating manufacturing demo data.',
              'IoTSense'
            );
          }
        );
      } else if (this.thirdFormGroup.value.useCase == 'construction') {
        let devices = {
          deviceName: this.devicename,
        };

        this.dataService.saveConstructiondemodata(devices).subscribe(
          (response) => {
            stepper.next();
            this._toaster['success'](
              'Generating construction data completed.',
              'IoTSense'
            );
          },
          (err) => {
            this.errordevice = err.error.message;
            this._toaster['error'](
              'Error in generating construction demo data.',
              'IoTSense'
            );
          }
        );
      } else if (this.thirdFormGroup.value.useCase == 'fleet') {
        let devices = {
          deviceName: this.devicename,
        };

        this.dataService.generateFleetDemoData(devices).subscribe(
          (response) => {
            stepper.next();
            this._toaster['success'](
              'Fleet data generation completed.',
              'IoTSense'
            );
          },
          (err) => {
            this.errordevice = err.error.message;
            this._toaster['error'](
              'Error in generating fleet demo data.',
              'IoTSense'
            );
          }
        );
      }
    }
  }
  upadtefun() {
    var user = {
      is_selected: false,
      iam:
        this.secondFormGroup.value.role == 'Other'
          ? this.secondFormGroup.value.otherrole
          : this.secondFormGroup.value.role,
      lookingFor:
        this.secondFormGroup.value.lookingfor == 'Other'
          ? this.secondFormGroup.value.otherlookingfor
          : this.secondFormGroup.value.lookingfor,
    };
    this._loginService.upadteuserDetails(user, this.userId).subscribe(
      (data) => {
        this.router.navigate(['home']);
      },
      (err) => {
        this._toaster['error'](err.error.message, 'User');
      }
    );
  }
  enabledemodata() {
    this.demodataflag = true;
  }
  generateFleetData() {
    this.devicename = 'ABCK';
    this.errordevice = null;
    swal({
      title: 'Generate fleet demo data',
      text: 'Are you sure, you want to generate the fleet demo data?',
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Generate',
          value: true,
          visible: true,
          className: 'bg-danger',
          closeModal: true,
        },
      },
    }).then((isConfirm) => {
      if (isConfirm) {
        let devices = {
          deviceName: this.devicename,
        };

        this.dataService.generateFleetDemoData(devices).subscribe(
          (response) => {
            this._toaster['info']('Generating fleet data.', 'IoTSense'),
              swal('Success!', 'Fleet data generation completed.', 'success');
          },
          (err) => {
            this.errordevice = err.error.message;
            swal('Error!', 'Error in generating fleet demo data.', 'error');
          }
        );
      } else {
        swal('Cancelled', '', 'error');
      }
    });
  }
  generateConstructionData() {
    this.devicename = 'ABCK';
    this.errordevice = null;
    swal({
      title: 'Generate construction demo data',
      text: 'Are you sure, you want to generate the construction demo data?',
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Generate',
          value: true,
          visible: true,
          className: 'bg-danger',
          closeModal: true,
        },
      },
    }).then((isConfirm) => {
      if (isConfirm) {
        let devices = {
          deviceName: this.devicename,
        };

        this.dataService.saveConstructiondemodata(devices).subscribe(
          (response) => {
            this._toaster['info']('Generating construction data.', 'IoTSense'),
              swal(
                'Success!',
                'Construction data generation completed.',
                'success'
              );
          },
          (err) => {
            this.errordevice = err.error.message;
            swal(
              'Error!',
              'Error in generating construction demo data.',
              'error'
            );
          }
        );
      } else {
        swal('Cancelled', '', 'error');
      }
    });
  }
  generateIIoTData() {
    this.devicename = 'ABCK';
    this.errordevice = null;
    swal({
      title: 'Generate manufacturing demo data',
      text: 'Are you sure, you want to generate the manufacturing demo data?',
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Generate',
          value: true,
          visible: true,
          className: 'bg-danger',
          closeModal: true,
        },
      },
    }).then((isConfirm) => {
      if (isConfirm) {
        let devices = {
          deviceName: this.devicename,
        };

        this.dataService.savedemodata(devices).subscribe(
          (response) => {
            this._toaster['info']('Generating manufacturing data.', 'IoTSense'),
              swal(
                'Success!',
                'Manufacturing data generation completed.',
                'success'
              );
          },
          (err) => {
            console.log('err', err);
            this.errordevice = err.error.message;
            swal(
              'Error!',
              'Error in generating manufacturing demo data.',
              'error'
            );
          }
        );
      } else {
        swal('Cancelled', '', 'error');
      }
    });
  }
}
