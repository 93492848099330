<section class="vh-100">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100" style="flex-direction: column;">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div style="text-align:center">
          <img class="img-fluid" src="../../../../assets/img/logo2.svg" alt="App Logo" />
          <h3 class="mb-4 mt-1" style="font-size: 24px;
             font-weight: 500;color: #463b3b;
             letter-spacing: 0.3px;">Resend Email</h3>
        </div>
        <form [formGroup]="registrationForm" (ngSubmit)="submitForm()" novalidate>
          <div class="col-md-12  groupp">
            <mat-form-field appearance="outline" style="display: block;">
              <mat-label>Email</mat-label>
              <mat-icon matPrefix class="my-icon">
                email
              </mat-icon>
              <input matInput (blur)="onBlur($event)" placeholder="Email" formControlName="email" autocomplete="off"
                required>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <button mat-raised-button class="btn btn-primary btn-lg" type="submit"
              style="width: 100%;margin-top: 8px;">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>