<div class="row">
  <div class="col-md-11" style="text-align: center;">
    <h3 class="headText1" style="margin-bottom: 0px !important;">View Datastream</h3>
  </div>
  <div class="col-md-1">
    <button type="button" class="close" mat-dialog-close>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<hr>
<div mat-dialog-content>
  <pre style="word-wrap: break-word; overflow: auto;">{{data | json}}</pre>
</div>