import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { gateway } from '../../environments/gateway';
import { subscription } from './pages/subscription/subscription.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private api: subscription
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    var isLoggedIn = '';
    if (gateway.apiUrl) {
      isLoggedIn = window['user']; //Uncomment to run independently
    } else {
      isLoggedIn = window['user']; //
    }

    if (isLoggedIn) {
      let response = true;
      const user = window['user'];
      if (user.is_Invited) {
        this.api.getSubDetails(user._id).subscribe((subscription) => {
          switch (subscription.planType) {
            case 'Basic':
            case 'Pro':
              if (subscription.status != 'active') {
                this.router.navigate(['/subscription']);
                response = false;
              } else {
                response = true;
              }
              break;

            case '':
              this.router.navigate(['/subscription']);
              response = false;
              break;
            case 'Free':
              const endDate = new Date(subscription.planEnd);
              if (endDate > new Date()) {
                response = true;
              } else {
                this.router.navigate(['/subscription']);
                response = false;
              }
              break;
          }
        });
      } else response = true;
      // console.log('\n\n🚀🚀🚀 + HOME NAVIGATION => ', response);
      return response;
    } else {
      if (gateway.apiUrl) {
        this.router.navigate(['/login']); //Uncomment to run independently
      } else {
        window.location.assign('/' + window['iotSenseRoot'] + 'login'); //Comment to run independently
      }
      return false;
    }
  }
}
