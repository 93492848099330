<div class="mat-typography">
  <div class="col-md-10 offset-md-1">
    <div style="text-align: center">
      <img
        class="img-fluid"
        src="../../../../assets/img/logo2.svg"
        alt="App Logo"
      />
      <h4>Find the perfect plan for your business.</h4>
      <!-- PLANS SECTION -->
      <div *ngIf="isPlansPage" class="text-center mt-4">
        <!-- BUTTON (toggle) -->
        <div
          class="d-flex align-items-center justify-content-center mt-4"
          style="margin-bottom: 2rem"
        >
          <input
            type="checkbox"
            class="toggle"
            id="toggle"
            [(ngModel)]="isMonthly"
            (change)="generatePlansArray(isMonthly)"
          />
          <label for="toggle">
            <span class="true">Monthly</span>
            <span class="false">Yearly</span>
          </label>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-around stretch"
          class="row justify-content-between"
          style="margin-bottom: 1rem !important; margin-top: 1rem !important"
        >
          <div
            *ngFor="let plan of isMonthly ? monthlyPlans : yearlyPlans"
            class="col-md-3"
          >
            <mat-card
              *ngIf="plan.status === 'active'"
              class="h-100 plan-card flex-column text-center"
              style="
                border-radius: 10px;
                border: 1px solid blue;
                box-shadow: none;
              "
            >
              <mat-card-header class="justify-content-center my-3">
                <mat-card-title
                  style="text-transform: capitalize"
                  [style]="getTextStyle(plan.type)"
                  class="font-weight-bold"
                  >{{ getFilteredName(plan.type) }}</mat-card-title
                >
              </mat-card-header>
              <mat-card-content class="flex-grow-1">
                <div
                  *ngIf="plan.type !== 'enterprise'"
                  class="d-flex align-items-center justify-content-center"
                  style="
                    margin-top: -0.5rem !important;
                    margin-bottom: 1.5rem !important;
                  "
                >
                  <h3>
                    <i
                      [class]="
                        showOnlyFreeTrial
                          ? 'fas fa-dollar-sign mr-2'
                          : 'fas fa-rupee-sign mr-2'
                      "
                    ></i>
                  </h3>
                  <h1 *ngIf="plan.type !== 'free trial'" class="mb-0">
                    {{ showOnlyFreeTrial ? "---" : plan.price }}
                  </h1>
                  <h1 *ngIf="plan.type === 'free trial'" class="mb-0">0</h1>

                  <h6 class="mb-0 ml-2">
                    {{ getDurationSymbol(plan) }}
                  </h6>
                </div>
                <div
                  *ngIf="isMonthly && plan.type !== 'enterprise'"
                  class="
                    text-muted
                    d-flex
                    align-items-center
                    justify-content-center
                    mt-n3
                  "
                >
                  You save
                  <i
                    [class]="
                      showOnlyFreeTrial
                        ? 'fas fa-dollar-sign mx-2'
                        : 'fas fa-rupee-sign mx-2'
                    "
                  ></i>
                  <h6
                    class="mb-0"
                    style="margin-left: -5px; margin-bottom: unset"
                  >
                    {{
                      showOnlyFreeTrial
                        ? "---"
                        : ((plan.price / 100) * 10 | number: "1.0-0")
                    }}
                  </h6>
                </div>
                <div *ngIf="plan.type === 'enterprise'">
                  <h1
                    style="
                      margin-bottom: 0.7rem !important;
                      margin-top: 0.5rem !important;
                    "
                  >
                    Let's talk
                  </h1>
                  <h6 class="mb-2" style="font-size: 14px">
                    Our team will be glad to assist
                  </h6>
                </div>
                <mat-card-actions
                  *ngIf="plan.type === 'basic monthly-1'"
                  class="m-2"
                >
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="basicMonthlyFlag == true"
                    (click)="classicModal.show()"
                  >
                    Current plan
                  </button>
                  <!-- <button mat-flat-button class="btn-primary"
                    *ngIf="basicMonthlyFlag==false  && (proMonthlyFlag==true && proYearlyFlag==true)" 
                    (click)="upgradeSubscription(false, plan)">
                    Upgrade
                  </button> -->
                  <button
                    mat-flat-button
                    class="btn-primary"
                    [disabled]="showOnlyFreeTrial"
                    *ngIf="basicMonthlyFlag == false"
                    (click)="
                      showOnlyFreeTrial
                        ? doNothing()
                        : handleSubscription(false, plan)
                    "
                  >
                    {{ showOnlyFreeTrial ? "Coming soon" : "Subscribe now" }}
                  </button>
                </mat-card-actions>
                <mat-card-actions
                  *ngIf="plan.type === 'basic yearly -1'"
                  class="m-2"
                >
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="basicYearlyFlag == true"
                    (click)="classicModal.show()"
                  >
                    Current plan
                  </button>
                  <button
                    mat-flat-button
                    class="btn-primary"
                    [disabled]="showOnlyFreeTrial"
                    [style.marginTop.px]="isMonthly ? 0 : 12"
                    *ngIf="basicYearlyFlag == false"
                    (click)="
                      showOnlyFreeTrial
                        ? doNothing()
                        : handleSubscription(false, plan)
                    "
                  >
                    {{ showOnlyFreeTrial ? "Coming soon" : "Subscribe now" }}
                  </button>
                </mat-card-actions>
                <mat-card-actions
                  class="m-2"
                  *ngIf="plan.type === 'pro monthly -1'"
                >
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="proMonthlyFlag == true"
                    (click)="classicModal.show()"
                  >
                    Current plan
                  </button>
                  <!-- <button mat-flat-button class="btn-primary"
                    *ngIf="proMonthlyFlag==false && (basicMonthlyFlag==true && basicYearlyFlag==true)"
                    (click)="upgradeSubscription(false, plan)">
                    Upgrade
                  </button> -->
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="proMonthlyFlag == false"
                    [disabled]="showOnlyFreeTrial"
                    (click)="
                      showOnlyFreeTrial
                        ? doNothing()
                        : handleSubscription(false, plan)
                    "
                  >
                    {{ showOnlyFreeTrial ? "Coming soon" : "Subscribe now" }}
                  </button>
                </mat-card-actions>
                <mat-card-actions
                  class="m-2"
                  *ngIf="plan.type === 'pro yearly -1'"
                >
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="proYearlyFlag == true"
                    (click)="classicModal.show()"
                  >
                    Current plan
                  </button>
                  <button
                    mat-flat-button
                    class="btn-primary"
                    [style.marginTop.px]="isMonthly ? 0 : 12"
                    *ngIf="proYearlyFlag == false"
                    (click)="handleSubscription(false, plan)"
                  >
                    {{ showOnlyFreeTrial ? "Coming soon" : "Subscribe now" }}
                  </button>
                </mat-card-actions>
                <mat-card-actions
                  class="m-2"
                  *ngIf="plan.type === 'enterprise'"
                >
                  <a
                    href="https://iotsense.io/#contactus"
                    target="_blank"
                    mat-flat-button
                    class="btn-primary contact-us-btn"
                  >
                    Contact Us
                  </a>
                </mat-card-actions>
                <mat-card-actions class="m-2" *ngIf="plan.type === 'free trial'">
                  <button
                    mat-flat-button
                    class="btn-primary"
                    [disabled]="userSubInfo.planType != ''"
                    (click)="handleSubscription(true, {})"
                  >
                    Start 14 days free trial
                  </button>
                </mat-card-actions>
                <mat-list role="list">
                  <mat-list-item
                    *ngFor="let benefit of plan.benefits"
                    class="text-left"
                    role="listitem"
                  >
                    <mat-icon mat-list-icon class="done" style="color: #07509d"
                      >done</mat-icon
                    >
                    <div mat-line [title]="benefit">
                      {{ benefit }}
                    </div>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
            <mat-card
              *ngIf="plan.status === ''"
              class="h-100 plan-card flex-column text-center"
              style="
                border-radius: 10px;
                border: 1px solid #ccc;
                box-shadow: none;
              "
            >
              <mat-card-header class="justify-content-center my-3">
                <mat-card-title
                  style="text-transform: capitalize"
                  [style]="getTextStyle(plan.type)"
                  class="font-weight-bold"
                  >{{ getFilteredName(plan.type) }}</mat-card-title
                >
              </mat-card-header>
              <mat-card-content class="flex-grow-1">
                <div
                  *ngIf="plan.type !== 'enterprise'"
                  class="d-flex align-items-center justify-content-center"
                  style="
                    margin-top: -0.5rem !important;
                    margin-bottom: 1.5rem !important;
                  "
                >
                  <h3>
                    <i
                      [class]="
                        showOnlyFreeTrial
                          ? 'fas fa-dollar-sign mr-2'
                          : 'fas fa-rupee-sign mr-2'
                      "
                    ></i>
                  </h3>
                  <h1 *ngIf="plan.type !== 'free trial'" class="mb-0">
                    {{ showOnlyFreeTrial ? "---" : plan.price }}
                  </h1>
                  <h1 *ngIf="plan.type === 'free trial'" class="mb-0">0</h1>

                  <h6 class="mb-0 ml-2" *ngIf="plan.type != 'free trial'">
                    {{ getDurationSymbol(plan) }}
                  </h6>
                </div>

                <div
                  *ngIf="isMonthly && plan.type !== 'enterprise'"
                  class="
                    text-muted
                    d-flex
                    align-items-center
                    justify-content-center
                    mt-n3
                  "
                >
                  You save
                  <i
                    [class]="
                      showOnlyFreeTrial
                        ? 'fas fa-dollar-sign mx-2'
                        : 'fas fa-rupee-sign mx-2'
                    "
                  ></i>
                  <h6
                    class="mb-0"
                    style="margin-left: -5px; margin-bottom: unset"
                  >
                    {{
                      showOnlyFreeTrial
                        ? "---"
                        : ((plan.price / 100) * 10 | number: "1.0-0")
                    }}
                  </h6>
                </div>
                <div *ngIf="plan.type === 'enterprise'">
                  <h1
                    style="
                      margin-bottom: 0.7rem !important;
                      margin-top: 0.5rem !important;
                    "
                  >
                    Let's talk
                  </h1>
                  <h6 class="mb-2" style="font-size: 14px">
                    Our team will be glad to assist
                  </h6>
                </div>
                <mat-card-actions
                  *ngIf="plan.type === 'basic monthly-1'"
                  class="m-2"
                >
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="basicMonthlyFlag == true"
                    (click)="classicModal.show()"
                  >
                    Current plan
                  </button>
                  <!-- <button mat-flat-button class="btn-primary"
                    *ngIf="basicMonthlyFlag==false  && (proMonthlyFlag==true && proYearlyFlag==true)" 
                    (click)="upgradeSubscription(false, plan)">
                    Upgrade
                  </button> -->
                  <button
                    mat-flat-button
                    class="btn-primary"
                    [disabled]="showOnlyFreeTrial"
                    *ngIf="basicMonthlyFlag == false"
                    (click)="
                      showOnlyFreeTrial
                        ? doNothing()
                        : handleSubscription(false, plan)
                    "
                  >
                    {{ showOnlyFreeTrial ? "Coming soon" : "Subscribe now" }}
                  </button>
                </mat-card-actions>
                <mat-card-actions
                  *ngIf="plan.type === 'basic yearly -1'"
                  class="m-2"
                >
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="basicYearlyFlag == true"
                    (click)="classicModal.show()"
                  >
                    Current plan
                  </button>
                  <!-- <button mat-flat-button class="btn-primary"
                    *ngIf="basicYearlyFlag==false && (proMonthlyFlag==true && proYearlyFlag==true)" 
                    (click)="upgradeSubscription(false, plan)">
                    Upgrade
                  </button> -->
                  <button
                    mat-flat-button
                    class="btn-primary"
                    [disabled]="showOnlyFreeTrial"
                    [style.marginTop.px]="isMonthly ? 0 : 12"
                    *ngIf="basicYearlyFlag == false"
                    (click)="
                      showOnlyFreeTrial
                        ? doNothing()
                        : handleSubscription(false, plan)
                    "
                  >
                    {{ showOnlyFreeTrial ? "Coming soon" : "Subscribe now" }}
                  </button>
                </mat-card-actions>
                <mat-card-actions
                  class="m-2"
                  *ngIf="plan.type === 'pro monthly -1'"
                >
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="proMonthlyFlag == true"
                    (click)="classicModal.show()"
                  >
                    Current plan
                  </button>
                  <!-- <button mat-flat-button class="btn-primary"
                    *ngIf="proMonthlyFlag==false && (basicMonthlyFlag==true && basicYearlyFlag==true)"
                    (click)="upgradeSubscription(false, plan)">
                    Upgrade
                  </button> -->
                  <button
                    mat-flat-button
                    class="btn-primary"
                    [disabled]="showOnlyFreeTrial"
                    *ngIf="proMonthlyFlag == false"
                    (click)="
                      showOnlyFreeTrial
                        ? doNothing()
                        : handleSubscription(false, plan)
                    "
                  >
                    {{ showOnlyFreeTrial ? "Coming soon" : "Subscribe now" }}
                  </button>
                </mat-card-actions>
                <mat-card-actions
                  class="m-2"
                  *ngIf="plan.type === 'pro yearly -1'"
                >
                  <button
                    mat-flat-button
                    class="btn-primary"
                    *ngIf="proYearlyFlag == true"
                    (click)="classicModal.show()"
                  >
                    Current plan
                  </button>
                  <!-- <button mat-flat-button class="btn-primary"
                    *ngIf="proYearlyFlag==false && (basicMonthlyFlag==true && basicYearlyFlag==true)" 
                    (click)="upgradeSubscription(false, plan)">
                    Upgrade
                  </button> -->
                  <button
                    mat-flat-button
                    class="btn-primary"
                    [disabled]="showOnlyFreeTrial"
                    [style.marginTop.px]="isMonthly ? 0 : 12"
                    *ngIf="proYearlyFlag == false"
                    (click)="
                      showOnlyFreeTrial
                        ? doNothing()
                        : handleSubscription(false, plan)
                    "
                  >
                    {{ showOnlyFreeTrial ? "Coming soon" : "Subscribe now" }}
                  </button>
                </mat-card-actions>
                <mat-card-actions
                  class="m-2"
                  *ngIf="plan.type === 'enterprise'"
                >
                  <a
                    href="https://iotsense.io/#contactus"
                    target="_blank"
                    mat-flat-button
                    class="btn-primary contact-us-btn"
                  >
                    Contact Us
                  </a>
                </mat-card-actions>
                <mat-card-actions class="m-2" *ngIf="plan.type === 'free trial'">
                  <ng-container
                    *ngIf="
                      userSubInfo.hasFreeTrialUsed &&
                        userSubInfo.planType == 'Free';
                      else elseTemplate
                    "
                  >
                    <button
                      mat-flat-button
                      class="btn-primary"
                      [style.marginTop.px]="isMonthly ? 0 : 12"
                      (click)="classicModal.show()"
                    >
                      Current plan
                    </button>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <button
                      mat-flat-button
                      class="btn-primary"
                      [disabled]="userSubInfo.planType != ''"
                      (click)="handleSubscription(true, {})"
                    >
                      Start 14 days free trial
                    </button>
                  </ng-template>
                </mat-card-actions>
                <mat-list role="list">
                  <mat-list-item
                    *ngFor="let benefit of plan.benefits"
                    class="text-left"
                    role="listitem"
                  >
                    <mat-icon mat-list-icon class="done" style="color: #07509d"
                      >done</mat-icon
                    >
                    <div mat-line [title]="benefit">
                      {{ benefit }}
                    </div>
                  </mat-list-item>
                </mat-list>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div
          *ngIf="
            showOnlyFreeTrial ||
            (user?.is_selected == false && userSubInfo?.status === 'active')
          "
          class="m-auto"
          style="text-align: center; margin-bottom: 1rem"
        >
          <button
            mat-stroked-button
            class="btn btn-outline"
            mat-dialog-close
            color="basic"
            [routerLink]="['/home']"
          >
           Done
          </button>
        </div>
      </div>
      <div *ngIf="!isPlansPage">
        <app-payment></app-payment>
      </div>
    </div>
  </div>
</div>

<!-- Razorpay payment processing dialog template -->
<ng-template #rzpPaymentProcessTemp>
  <mat-dialog-content class="mat-typography">
    <h1 class="text-center my-3">Please wait. Payment is processing...</h1>
    <h1 class="text-center text-warning">DO NOT REFRESH THE PAGE</h1>
    <app-progress-spinner
      [diameter]="120"
      *ngIf="showSpinner"
      class="spinner"
    ></app-progress-spinner>
  </mat-dialog-content>
</ng-template>

<div
  class="modal fade"
  bsModal
  #classicModal="bs-modal"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div
      class="modal-content"
      centered="true"
      style="background-color: #f0f4ff"
    >
      <div class="modal-header">
        <div class="col-md-10" style="text-align: center">
          <h3
            class="headText1"
            style="margin-bottom: 0px !important; padding-left: 12%"
          >
            Current Plan
          </h3>
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="classicModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div mat-dialog-content style="padding-top: 2%">
          <div class="mda-row row">
            <div class="col-md-12">
              <div
                class="mda-row row"
                style="
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="col-md-5" style="text-align: end">
                  <label style="font-weight: bolder">Status</label>
                </div>
                <div class="col-md-6" style="text-align: start">
                  <label
                    [style.color]="
                      userSubInfo.status === 'active' ? 'green' : 'red'
                    "
                  >
                    {{
                      userSubInfo.status === "active"
                        ? (userSubInfo.status | titlecase)
                        : "Inactive"
                    }}
                  </label>
                </div>
              </div>
              <div
                class="mda-row row"
                style="
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="col-md-5" style="text-align: end">
                  <label style="font-weight: bolder">Plan Type</label>
                </div>
                <div class="col-md-6" style="text-align: start">
                  <label *ngIf="userSubInfo.planType!='Free'">
                    {{ userSubInfo.planType | uppercase }} ({{
                      userSubInfo.planDuration
                    }})
                  </label>
                  <label *ngIf="userSubInfo.planType=='Free'">
                    Free Trial&nbsp;({{
                      userSubInfo.planDuration
                    }})
                  </label>
                </div>
              </div>
              <div
                class="mda-row row"
                style="
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="col-md-5" style="text-align: end">
                  <label style="font-weight: bolder">Activated Date</label>
                </div>
                <div class="col-md-6" style="text-align: start">
                  <ng-container
                    *ngIf="userSubInfo.planStart; else elseTemplate"
                  >
                    <label>
                      {{ userSubInfo.planStart | date: "fullDate" }}
                    </label>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <label>
                      <span
                        class="spinner-border spinner-border-sm text-primary"
                      ></span>
                    </label>
                  </ng-template>
                </div>
              </div>
              <div
                class="mda-row row"
                style="
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="col-md-5" style="text-align: end">
                  <label style="font-weight: bolder">Expiry Date</label>
                </div>
                <div class="col-md-6" style="text-align: start">
                  <ng-container *ngIf="userSubInfo.planEnd; else elseTemplate">
                    <label>
                      {{ userSubInfo.planEnd | date: "fullDate" }}
                    </label>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <label>
                      <span
                        class="spinner-border spinner-border-sm text-primary"
                      ></span>
                    </label>
                  </ng-template>
                </div>
              </div>
              <div
                class="mda-row row"
                *ngIf="userSubInfo.planType != 'Free'"
                style="
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                "
              >
                <div class="col-md-5" style="text-align: end">
                  <label style="font-weight: bolder">Upcoming Plan</label>
                </div>
                <div class="col-md-6" style="text-align: start">
                  <label *ngIf="userSubInfo.upcomingPlans">
                    {{ userSubInfo.upcomingPlans | titlecase }}
                  </label>
                  <label *ngIf="!userSubInfo.upcomingPlans"> N/A </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal-footer"
          style="text-align: center; border-top: none; justify-content: center"
        >
          <button
            *ngIf="
              userSubInfo.planType != 'Free' && userSubInfo.status === 'active'
            "
            mat-stroked-button
            style="border-color: red; color: red"
            (click)="cancelSubscription()"
            [hidden]="user.isCancelled"
          >
            Cancel Subscription
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
