import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {

  notifications: any[];
  constructor() { }

  get data(): any {
    return this.notifications;
  }

  set data(val: any) {
    this.notifications = val;
  }

}
