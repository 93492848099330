import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { gateway } from 'src/environments/gateway';

const io = require('socket.io-client');

@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  socket: any;

  constructor() { }

  /**
   * Creates connection with the socket-io server
   */
  setupSocketConnection(): void {
    if (this.socket && this.socket.connected) {
      this.socket = this.socket;
      //  console.log('🚀 ~ 1 --->', this.socket);
    } else {
      this.socket = io(gateway.apiUrl, {});
      // console.log('🚀 ~ 2 --->', this.socket);
    }
  }

  /**
   * Listens to the parsed event name
   * @param eventName
   * @returns observable
   */
  listen(eventName: string): Observable<any> {
    if (this.socket) {
      return new Observable((subscriber) => {
        this.socket.on(eventName, (data: any) => {
          subscriber.next(data);
        });
      });
    }
  }

  /**
   * Emits the data with event name and original data
   * @param eventName
   * @param data
   */
  emit(eventName: string, data: any): void {
    if (this.socket) {
      this.socket.emit(eventName, data);
    }
  }

  /**
   * Removes the listener from socket-io
   * @param eventName
   */
  removeListener(eventName: string): void {
    if (this.socket) {
      this.socket.removeListener(eventName);
    }
  }
}
